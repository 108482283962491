import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { News } from '../../../../@domain/news/entities/news.interface';
import { NewsInfo } from '../../../../@generated';

@Component({
    selector: 'app-news-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ ImageDirective ],
    styles: `
        @import "../../../../../styles/variables";

        .latest-news {
            display: flex;
            margin-bottom: 2.5rem;

            img {
                width: 150px;
                height: 60px;
                object-fit: cover;
                border-radius: 4px;
                margin-right: 1.5rem;
            }

            .text {
                h3 {
                    color: $dark;
                    font-size: 20px;
                    font-weight: 700;
                }

                p {
                    padding-bottom: 0.5rem;
                    color: $grey;
                    font-weight: 400;
                    font-size: 16px;
                }

                span {
                    font-size: 16px;
                    font-weight: 500;
                    color: $grey;
                }
            }

            @media screen and (max-width: 600px) {
                img {
                    width: 100%;
                    height: 100px;
                    margin: 0;
                }
                flex-direction: column;
            }
        }
    `,
    template: `
        <div class="latest-news">
            <div class="img-div">
                <img [src]="newsItem.thumbnail || './assets/download.png'" alt="news" loading="lazy"/>
            </div>
            <div class="text">
                <h3>{{ newsItem.title }}</h3>
                <span class="date">{{ newsItem.date }}, {{ newsItem.author.name }}</span>
            </div>
        </div>
    `,
})
export class NewsItemComponent {
  @Input({required: true}) newsItem!: NewsInfo;
}
