import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { Logger } from 'src/app/@common/log/logger';
import { Page } from '../../../@common/entities/page.interface';
import { NewsInfo, NewsInfoSort, SortOrder, V2NewsResource } from '../../../@generated';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    private logger = inject(Logger);
    private newsResource = inject(V2NewsResource);

    public getAllNews(
        limit: number,
        page: number,
        order: SortOrder,
        sort: NewsInfoSort | undefined
    ): Observable<Page<NewsInfo>> {
        return this.newsResource.getNewsInfos(limit, page, order, sort).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get news failed', e);
                return of({ count: 0, results: [] });
            })
        );
    }

}
